import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Story() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div style={{ background: "#f9eded" }}>
      <Header />
      <section className="section1-story">
        <img className="ann" src="./images/ann.svg" alt="ann" />
      </section>
      <section className="section2-story">
        <div className="story-text1">Milestones worth celebrating</div>
        <div className="section2-story-inner">
          <div className="story-box">
            <div className="story-text2">OCT 2022</div>
            <div className="story-text3">
              Launched Bakerverse Suites Hotel in Benin City
            </div>
          </div>
          <div className="story-box1">
            <div className="story-text2">NOV 2022</div>
            <div className="story-text3">
              Launched Shortlet Apartments as an extension of the hotel
            </div>
          </div>
          <div className="story-box1">
            <div className="story-text2">AUG 2022</div>
            <div className="story-text3">
              Expanded into agriculture and sold around 2010+ Farm produce to
              consumers across Lagos State
            </div>
          </div>
          <div className="story-box1">
            <div className="story-text2">JAN 2022</div>
            <div className="story-text3">Launch of Moonshot Estate</div>
          </div>
          <div className="story-box1">
            <div className="story-text2">FEB 2023</div>
            <div className="story-text3">Launch of Stake City</div>
          </div>
          <div className="story-box1">
            <div className="story-text2">2023</div>
            <div className="story-text3">
              Expanded farm property to 28 Acres
            </div>
          </div>
        </div>
      </section>
      <section className="section4-home">
        <div className="home-box2">
          <div className="home-text10">Interested in one of our services?</div>
          <Link to="/contact">
            <button className="home-but4">Talk to us</button>
          </Link>
        </div>
      </section>
      <Footer />
    </div>
  );
}
