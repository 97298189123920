import React, { useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import axios from "axios";
import { toast } from "react-toastify";

export default function Contact() {
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();

  const createReq = async (evt) => {
    evt.preventDefault();
    const id = toast.loading("Submission in progress..");
    try {
      const cred = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        message: messageRef.current.value,
      };
      const res = await axios.post(`https://bakerhomes.io/api/v1/bil`, cred);
      if (res.data.code === 200) {
        toast.update(id, {
          render: "Request Submitted Successfull",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

        nameRef.current.value = "";
        emailRef.current.value = "";
        phoneRef.current.value = "";
        messageRef.current.value = "";
      }
    } catch (err) {
      console.log(err);
      toast.update(id, {
        render: `${err.response}`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
      });
    }
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Header />
      <main className="main-rm">
        <section className="contact-section1"></section>

        <section className="contact-section2">
          <div className="contact-text1">Happy to help!</div>
          <div className="contact-text2">
            We are always available to provide you with guidance and help
          </div>

          <form onSubmit={createReq} className="contact-section2-inner">
            <div>
              <div className="contact-text3">
                For general enquiry, request & complaints.
              </div>

              <div className="input-box">
                <label className="label">Full Name</label>
                <input
                  ref={nameRef}
                  className="input"
                  placeholder="Enter your Full Name"
                />
              </div>

              <div className="input-box">
                <label className="label">Phone Number</label>
                <input
                  ref={phoneRef}
                  className="input"
                  placeholder="Enter your phone number"
                />
              </div>

              <div className="input-box">
                <label className="label">Email Address</label>
                <input
                  ref={emailRef}
                  className="input"
                  type="email"
                  placeholder="Enter your email address"
                />
              </div>

              <div className="input-box">
                <label className="label">Message</label>
                <textarea ref={messageRef} className="textarea">
                  {" "}
                </textarea>
              </div>

              <button className="cbut">Send Message</button>
            </div>
            <div>
              <div className="contact-text3">Social Media</div>
              <div className="contact-social">
                <a
                  href="https://www.instagram.com/bakerindustrieslimited/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="./images/insta.svg" alt="instagram" />
                </a>
                <a
                  href="https://www.linkedin.com/company/bakerindustrieslimited"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="./images/lin1.svg" alt="linkedin" />
                </a>
                <a
                  href="https://www.facebook.com/bakerindustrieslimited"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="./images/fac.svg" alt="facebook" />
                </a>
                <a
                  href="https://twitter.com/bakerind_"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="./images/tw.svg" alt="twitter" />
                </a>
              </div>

              <div className="input-box2">
                <div className="contact-text4">
                  <img src="./images/ph.svg" alt="phone" /> Call us
                </div>
                <div className="contact-text5 ">(+234) 08038512489</div>
                <div className="contact-text5 ">(+234) 016344119</div>
              </div>

              <div className="input-box2">
                <div className="contact-text4">
                  <img src="./images/env.svg" alt="email" /> Email
                </div>
                <div className="contact-text5">contact@bakerindustries.io</div>
              </div>

              <div className="input-box2">
                <div className="contact-text4">
                  <img src="./images/home.svg" alt="home" /> Head Office
                </div>
                <div className="contact-text5">
                  No 32, Aje Street, Sabo Yaba, Lagos State
                </div>
              </div>

              <div className="input-box2">
                <div className="contact-text4">
                  <img src="./images/home.svg" alt="home" /> Abuja Office
                </div>
                <div className="contact-text5">
                  Plot No 1095, Cadastral zone, C5 SHM office complex, Along
                  Kado/Mabushi express way, Mabushi District, Abuja
                </div>
              </div>

              <div className="whflex">
                <div style={{ paddingRight: "30px" }} className="contact-text4">
                  Need a quicker response?
                </div>
                <a
                  href="https://wa.me/2347018113880"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="./images/what.svg" alt="whatsapp" />
                </a>
              </div>
            </div>
          </form>
        </section>
      </main>
      <Footer />
    </div>
  );
}
