import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./bem/header.css";
import "./bem/home.css";
import "./bem/story.css";
import "./bem/about.css";
import "./bem/footer.css";
import "./bem/rm.css";
import "./bem/contact.css";
import About from "./pages/About";
import Story from "./pages/Story";
import Roadmap from "./pages/Roadmap";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/story" element={<Story />} />
        <Route exact path="/roadmap" element={<Roadmap />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer autoClose={15000} />
    </BrowserRouter>
  );
}

export default App;
