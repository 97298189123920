import React, { useState, useRef, useEffect } from "react";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import Header from "../components/Header";

export default function Home() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Header />

      <main>
        <section className="section1-home">
          <div className="section1-home-inner">
            <div className="home-text1">Cultivating Excellence in </div>
            <img className="bg1" src="./images/bg1.svg" alt="" />
          </div>
        </section>
        <section className="section2-home">
          <div className="home-text2">
            Welcome to Baker Industries Limited, a city of Industries providing
            solutions in the Real Estate, Technology, Hospitality and
            Agricultural sector. We are committed to providing exceptional
            solutions and services to our clients worldwide, with a focus on
            innovation, expertise, and client satisfaction.
          </div>
        </section>
        <section className="section3-home">
          <div className="section3-home-inner1">
            <div>
              <div className="home-text3">
                We are extremely proud of our subsidiaries
              </div>
              <div className="home-text4">
                The heart of real estate solutions
              </div>
              <div className="home-text5">
                In the real estate sector, we aim to develop properties that
                meet the evolving needs of our clients while minimizing the
                environmental impact. Baker Homes provide outstanding services
                in the real estate sector thanks to a committed team of experts.
              </div>

              <a href="https://bakerhomes.io/" target="_blank" rel="noreferrer">
                <button className="home-but1">Learn More</button>
              </a>
            </div>
            <img className="img1" src="./images/img1.png" alt="img1" />
          </div>

          <div className="section3-home-inner2">
            <div className="home-text6">
              Creating Unforgettable Customer Experience
            </div>
            <div className="home-text7">
              In the hospitality industry, we strive to provide our guests with
              unforgettable experiences by offering personalized services and
              exceptional amenities.
            </div>

            <a
              href="https://bakerversesuites.com/"
              target="_blank"
              rel="noreferrer"
            >
              <button className="home-but2">Learn More</button>
            </a>
          </div>

          <div className="img-flex">
            <img className="block img2" src="./images/img2.png" alt="img2" />

            <div>
              <img className="block1 img" src="./images/img3.png" alt="img3" />
              <img className="block img" src="./images/img4.png" alt="img4" />
            </div>

            <img className="block img5" src="./images/img5.png" alt="img5" />
          </div>

          <div className="section3-home-inner3">
            <img className="img6" src="./images/img6.png" alt="img6" />
            <div className="home-box">
              <div className="home-text8">
                Passionate about producing high-quality, Naturally-grown crops
              </div>
              <div className="home-text9">
                We believe that food should be fresh, delicious, and nourishing,
                which is why we carefully cultivate our crops using traditional
                farming techniques that prioritize our customers' health and
                well-being.
              </div>
              <a
                href="https://bakerversefarms.com/"
                target="_blank"
                rel="noreferrer"
              >
                <button className="home-but3">Learn More</button>
              </a>
            </div>
          </div>
        </section>
        <section className="section4-home">
          <div className="home-box2">
            <div className="home-text10">
              Interested in one of our services?
            </div>
            <Link to="/contact">
              <button className="home-but4">Talk to us</button>
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
