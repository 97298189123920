import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-inner">
        <div>
          <img src="./images/logof.svg" alt="logo" />
          <div className="footer-text2">Building the future of tomorrow</div>
          <div className="footer-text3 ">
            No 32, Aje Street, Sabo Yaba, Lagos State
          </div>
          <div className="footer-text3 ">(+234) 08162750523</div>
          <div className="footer-text3 ">(+234) 08169584468</div>
        </div>
        <div className="footer2">
          <div className="footer-inner2">
            <div className="footer-inner3">
              <Link to="/about">
                <div className="footer-text4">About us</div>
              </Link>

              <Link to="/story">
                <div className="footer-text4">Our Story</div>
              </Link>

              <Link to="/roadmap">
                <div className="footer-text4">Roadmap</div>
              </Link>

              <Link to="/contact">
                <div className="footer-text4">Say Hello</div>
              </Link>
            </div>
            <img
              onClick={() => {
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
              style={{ cursor: "pointer" }}
              className="up"
              src="./images/up.svg"
              alt="up"
            />
          </div>
        </div>
      </div>
      <div className="social">
        <a
          href="https://www.facebook.com/bakerindustrieslimited"
          target="_blank"
          rel="noreferrer"
        >
          <img src="./images/facebook.svg" alt="logo" />
        </a>

        <a
          href="https://www.linkedin.com/company/bakerindustrieslimited"
          target="_blank"
          rel="noreferrer"
        >
          <img src="./images/linkedin.svg" alt="logo" />
        </a>

        <a
          href="https://www.instagram.com/bakerindustrieslimited/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="./images/instagram.svg" alt="logo" />
        </a>
        <a
          href="https://twitter.com/bakerind_"
          target="_blank"
          rel="noreferrer"
        >
          <img src="./images/twitter.svg" alt="logo" />
        </a>
      </div>
      <div className="footer-text1">
        {" "}
        &copy; 2023 Baker Industries Limited. All rights Reserved
      </div>
    </div>
  );
}
