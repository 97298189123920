/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const [tog, setTog] = useState(true);
  const toggle = () => {
    setTog(!tog);
  };
  return (
    <div className="head">
      <div className="header">
        <Link to="/">
          <img className="logo" src="./images/logo.svg" alt="BIL Logo" />
        </Link>

        <div
          className={tog ? "headerInner animation2" : "headerInner animation1"}
        >
          <Link to="/about">
            <div className="headerItem">About us</div>
          </Link>
          <Link to="/story">
            <div className="headerItem">Our Story</div>
          </Link>
          <Link to="/roadmap">
            <div className="headerItem">Roadmap</div>
          </Link>
          <Link to="/contact">
            <div className="headerItem">Say Hello </div>
          </Link>
        </div>

        <img
          className="ham"
          onClick={toggle}
          src="./images/ham.svg"
          alt="ham"
        />
      </div>
    </div>
  );
}
