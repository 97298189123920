import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function About() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Header />
      <main>
        <section className="section1-about">
          <div className="section1-about-inner1">
            <div className="about-text1 ">About us</div>
            <div className="about-text2">
              Baker Industries Limited was founded in 2022 as a real estate
              development and Proptech company before expanding into thriving
              industries. Our areas of expertise include real estate,
              technology, hospitality, and agriculture. We are a dynamic and
              innovative company that takes pride in providing exceptional
              client experiences and services to clients all over the world.
              Over time, we established three subsidiaries: Baker Homes,
              Bakerverse Suites Limited, and Bakerverse Farms Limited.{" "}
            </div>
          </div>
          <div className="section1-about-inner2">
            <img className="img7" src="./images/biln.png" alt="img7" />
          </div>
          <div className="section1-about-inner1">
            <div className="about-text1 ">Our Mission</div>
            <div className="about-text2">
              Our mission is to continuously provide great value to our
              stakeholders, partners, and clients by prioritizing profitable
              investments, innovation, high-quality work, and integrity.
            </div>
          </div>
        </section>
        <section className="section2-about">
          <div className="section2-about-inner">
            <div className="about-text3">
              We prioritise meeting our clients' needs and doing so with
              integrity out of a sense of service to them.
            </div>

            <div className="about-box">
              <div className="about-text4">
                We Believe Our Clients Come First
              </div>
              <div className="about-text5 ">
                Client commitment is a fundamental value that every company
                should prioritize. We believe that our clients come first. We
                understand that the success of our companies is tied to the
                success of our clients. Therefore, we take the time to
                understand our client's needs, preferences, and goals and work
                tirelessly to meet or exceed their expectations
              </div>
            </div>

            <div className="about-box">
              <div className="about-text4">
                Integrity is at the core of how we work
              </div>
              <div className="about-text5 ">
                Prioritizing integrity has helped us build a system where
                transparency and accountability are our watchwords, and this has
                helped us gain the trust of all our clients. Integrity has also
                helped us create a workplace culture of honesty, trust, and
                ethical behaviour which is crucial to our company’s success
              </div>
            </div>

            <div className="about-box">
              <div className="about-text4">We Value Teamwork & Inclusion</div>
              <div className="about-text5 ">
                Over time we have established an inclusive environment which has
                created an environment where everyone feels valued and respected
                while also promoting a collaborative environment leading to more
                efficient problem-solving and a uniform mindset among teams
              </div>


            </div>
          </div>
        </section>
        <section className="section3-about">
          <div className="section3-about-inner">
            <div className="about-text6">Meet The BIL Team</div>

            <div className="about-flex">
              <div className="about-box2">
                <img className="person" src="./images/p1.png" alt="p1" />
                <img className="lin" src="./images/lin.svg" alt="linkedin" />
                <div className="about-text7">Bakare Folarin </div>
                <div className="about-text8">CEO & Founder</div>
              </div>
              <div className="about-box2">
                <img className="person" src="./images/p4.png" alt="p4" />
                <img className="lin" src="./images/lin.svg" alt="linkedin" />
                <div className="about-text7">Kingsley I.Ifoga </div>
                <div className="about-text8">General Manager</div>
              </div>
              <div className="about-box2">
                <img className="person" src="./images/p2.png" alt="p2" />
                <img className="lin" src="./images/lin.svg" alt="linkedin" />
                <div className="about-text7">Osundina Oluwasegun</div>
                <div className="about-text8">
                  Manager, Projects & Products Development
                </div>
              </div>
              <div className="about-box2">
                <img className="person" src="./images/k3.jpg" alt="p3" />
                <img className="lin" src="./images/lin.svg" alt="linkedin" />
                <div className="about-text7">Kenny Adelakun </div>
                <div className="about-text8">Head, Growth & Business Developmen</div>
              </div>
            </div>
          </div>
        </section>
        <section className="section4-home">
          <div className="home-box2">
            <div className="home-text10">
              Interested in one of our services?
            </div>
            <Link to="/contact">
              <button className="home-but4">Talk to us</button>
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
