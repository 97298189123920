import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Roadmap() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Header />
      <main className="main-rm">
        <div className="rm-text1">Roadmap 2024</div>

        <section className="rmflex">
          <div className="rmbox1">
            <div className="rminner">Q1</div>
            <ul className="ul">
              <li>
                BH Soft-launch for Fork Hill Estate (Kuje, Abuja) & Drip City
                Estate (Epe, Lagos)
              </li>
              <li>Onboard Marketing Agencies to drive Sales</li>
              <li>BVFL expansion from 15 to 20Acres, and SFI 3.0 kick off </li>
              <li>
                Installation of 33Kva Transformer at BVSL for uninterrupted
                power supply{" "}
              </li>
            </ul>
          </div>

          <div className="rmbox2">
            <div style={{ color: "#00f47f" }} className="rminner">
              Q2
            </div>

            <ul className="ul">
              <li>New product acquisition for BH inventory </li>
              <li>
                Accelerated Marketing & Sales activities to scale revenue
                generation
              </li>
              <li>
                Activate the BIL Marketplace App for active Investor on-boarding{" "}
              </li>

              <li>BVFL launch of SFI 4.0 </li>
              <li>Setup a fully functional "DripperZ Club" at BVSL </li>
              <li>Market Readiness for BSERVE & Recurra-PAY </li>
            </ul>
          </div>

          <div className="rmbox3">
            <div style={{ color: "#E000F4" }} className="rminner">
              Q3
            </div>

            <ul className="ul">
              <li>
                Increase development portfolio for BH Project/Construction team{" "}
              </li>
              <li>BVFL expansion to 23 Acres & launch of SFI 5.0</li>
              <li>Secure an industrial partner/client at BVFL</li>
              <li>Setup a "Games Den" at BVSL</li>
            </ul>
          </div>

          <div className="rmbox4">
            <div style={{ color: "#0070f4" }} className="rminner">
              Q4
            </div>

            <ul className="ul">
              <li>New product acquisition for BH inventory</li>
              <li>Marketers Awards & Rewards - MARs Ceremony</li>
              <li>
                BVFL Brand promotion via an exclusive "Farm Tour" event for
                Investors/Partners
              </li>
            </ul>
          </div>
        </section>
        <section className="section4-home">
          <div className="home-box2">
            <div className="home-text10">
              Interested in one of our services?
            </div>
            <Link to="/contact">
              <button className="home-but4">Talk to us</button>
            </Link>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
