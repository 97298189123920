import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function NotFound() {
  return (
    <div style={{ background: "#f9eded" }}>
      <Header />
      <main className="not">
        <div className="not-text1">Sorry (: </div>
        <div className="not-text2">Page Not Found</div>
        <div className="not-text3">
          The link you followed may be broken or we may have removed the page.
        </div>
      </main>
      <Footer />
    </div>
  );
}
